/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable default-case */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import moment from 'moment';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Done from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';


// core components
import GridItem from './Grid/GridItem';
import GridContainer from './Grid/GridContainer';
import CustomInput from './CustomInput/CustomInput';
import Card from './card/Card';
import CardHeader from './card/CardHeader';
import CardBody from './card/CardBody';
import CardFooter from './card/CardFooter';
import Snackbar from './Snackbar/Snackbar';

import MenuContext from '../context/menu-context';

const buttonstyles = theme => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
});

const CssTextField = withStyles(buttonstyles)(TextField);

const styles = theme => ({
  grid: {
    width: '80%',
    marginTop: 10,
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  fileInput: {
    display: 'none',
  },
  filePaper: {
    // padding: theme.spacing(2),
    // background: theme.palette.primary.main,
    color: theme.palette.primary.main,
    'border-color': theme.palette.primary.main,

  },
  fileButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

  },
  progress: {
    // flexGrow: 1,
  },
});

const PublicationForm = (props) => {
  // Hooks
  const { classes } = props;
  const [showNoFileNotify, setShowNoFileNotify] = useState(false);
  const [showSuccessNotify, setShowSuccessNotify] = useState(false);
  const [file, setFile] = useState();
  const { firebaseHook } = useContext(MenuContext);
  const [completed, setCompleted] = React.useState(0);



  const startShowNoFileNotification = () => {
    setShowNoFileNotify(true);
    setTimeout(
      () => {
        setShowNoFileNotify(false);
      },
      4000,
    );
  };

  const startShowSuccessNotify = () => {
    setShowSuccessNotify(true);
    setTimeout(
      () => {
        setShowSuccessNotify(false);
      },
      4000,
    );
  };

  const uploadFileAndSetFirestore = (formValues) => {
    // Create the file metadata
    const metadata = {
      contentType: 'application/pdf',
    };

    let publicationPdfURL;

    const storageRef = firebaseHook.storage().ref();

    // Upload file and metadata to the object 'images/mountains.jpg'
    const uploadTask = storageRef.child(`publications/${file.name}`).put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebaseHook.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setCompleted(progress);
        console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case firebaseHook.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebaseHook.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          publicationPdfURL = downloadURL;
          console.log('File available at', downloadURL);

          const publication = {
            ...formValues,
            createdAt: moment().valueOf(),
            fileName: file.name,

          };

          if (publicationPdfURL) { 
            props.onSubmit(publication); 
            startShowSuccessNotify();
          
          }
        });
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fields = ['title', 'translatedTitle', 'authors', 'doi', 'venue', 'year', 'shortVenue'];
    const formElements = e.target.elements;

    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    if (file) {
      uploadFileAndSetFirestore(formValues);
    } else {
      startShowNoFileNotification();
    }
  };

  // setFile hook to the selected file in input
  const handleFileSelect = ({ target }) => {
    setFile(target.files[0]);
  };

  return (
    <React.Fragment>
      <LinearProgress variant="determinate" value={completed} hidden={(completed <= 0) || (completed === 100)} />
      <form onSubmit={onSubmit}>
        <Grid container justify="center">
          <Grid spacing={4} alignItems="center" justify="center" container className={classes.grid}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New Publication Form</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Publication title"
                      id="title"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        name: 'title',
                        multiline: true,
                        rows: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Translated title"
                      id="translatedTitle"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: false,
                        name: 'translatedTitle',
                        multiline: true,
                        rows: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Authors"
                      id="authors"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        name: 'authors',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="DOI"
                      id="doi"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: false,
                        name: 'doi',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Year"
                      id="year"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        name: 'year',
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <CustomInput
                      labelText="Venue name"
                      id="venue"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        name: 'venue',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Abbreviated venue name"
                      id="shortVenue"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        required: true,
                        name: 'shortVenue',
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      className={classes.fileButton}
                    >
                      Select pdf
                      <input
                        accept="application/pdf"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                      />
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    {file && (
                      <CssTextField
                        id="standard-read-only-input"
                        label="Selected File"
                        value={file.name}
                        className={classes.filePaper}
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" variant="contained" color="primary">Add Publication</Button>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        place="tr"
        color="warning"
        icon={Info}
        message=" Please select a pdf file!"
        open={showNoFileNotify}
        closeNotification={() => setShowNoFileNotify(false)}
        close
      />
      <Snackbar
        place="tr"
        color="success"
        icon={Done}
        message=" Publication registered successfully!"
        open={showSuccessNotify}
        closeNotification={() => setShowSuccessNotify(false)}
        close
      />
    </React.Fragment>
  );
};

PublicationForm.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default withStyles(styles)(PublicationForm);

/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import MenuContext from '../context/menu-context';
import PublicationForm from '../components/PublicationForm';

import { addPublicationFirestore } from '../actions/publications';


const styles = theme => ({
  grid: {
    // width: '100%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
      marginTop: 40,
    },
  },
  progress: {
    margin: theme.spacing(2),
  },
  container: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
    'text-align': 'center',
    'min-height': '50vh',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  block: {
    padding: theme.spacing(2),
  },
});

const Publications = (props) => {
  const { classes } = props;
  const { setMenu, firebaseHook } = useContext(MenuContext);


  useEffect(() => {
    const currentPath = props.location.pathname;
    setMenu(currentPath);
  }, []);

  const onSubmit = (publication) => {
    console.log(publication);
    props.startEditExpense(firebaseHook.db, publication);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {
        props.isAuthenticated ? (
          <PublicationForm onSubmit={onSubmit} />
        ) : (
          <p>Not Authorized</p>
        )
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: !!state.auth.uid,
});

const mapDispatchToProps = dispatch => ({
  startEditExpense: (database, publication) => dispatch(addPublicationFirestore(database, publication)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Publications));
